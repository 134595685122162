<template>
  <div>
    <div
      ref="Hello"
      class="c-slide f-inline-block"
      @mousemove="mousemove"
      @change="a"
    >
      <!-- @click="a" -->
      <div class="slide-runway" ref="name">
        <div class="slide-bar" ref="bar" :style="{ width: x }"></div>
        <div
          class="c-slide-button slide-btn"
          ref="tu"
          :style="{ left: x }"
          @mousedown="move"
        >
          <div class="c-slide-tooltip tooltip">
            {{ title }}
          </div>
        </div>
      </div>
      <div class="slide-rule f-cl" style="display: flex">
        <span class="rule f-fl" style="width: 10%">130以下</span>
        <!-- :style="bgImg" -->
        <span
          class="rule f-fl rule-bg"
          style="width: 10%"
          v-for="item in data"
          :key="item"
          >{{ item }}</span
        >
        <span class="rule f-fl" style="width: 10%">210以上</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      x: "",
      title: "",
      data: [130, 140, 150, 160, 170, 180, 190, 200],
      intervalID: null, //初始化
      // bgImg: {
      //   backgroundImage:
      //     "url(" + require("../../assets/images/rule.079996f.png") + ")",
      //   backgroundRepeat: "no-repeat",
      // },
      // positionX: "",
      // positionY: ""
    };
  },
  mounted() {
    this.Initial();
  },
  computed: {},
  methods: {
    //设置默认数据
    Initial() {
      //初始化获取元素宽度
      let TotalWidth = this.$refs.name.clientWidth;
      //将数据划分
      let PerWidth = TotalWidth / 100;
      //调用默认身高
      this.linearAnimation(PerWidth, PerWidth * 40);
    },
    //初始化自动移动
    linearAnimation(step, target) {
      // let intervalID = null;
      let begin = 0,
        step_len = step || 0;
      // clearInterval(intervalID);
      clearInterval(this.intervalID);
      // intervalID = setInterval(() => {
      this.intervalID = setInterval(() => {
        begin += step_len;
        if (begin >= target) {
          begin = target;
          // clearInterval(intervalID);
          clearInterval(this.intervalID);
        }
        // box.style.marginLeft = begin + "px";
        this.$refs.bar.style.width = begin + "px";
        this.$refs.tu.style.left = begin + "px";
        this.CalculatedWidth(begin);
      }, 50);
    },
    //计算实际所占比(显示框)
    CalculatedWidth(w) {
      //初始化获取元素宽度
      let TotalWidth = this.$refs.name.clientWidth;
      // console.log("初始化h", TotalWidth);
      //将数据划分
      let PerWidth = TotalWidth / 100;
      //小小格子
      //   let smallWidth = PerWidth / 10;
      //   console.log(smallWidth);
      // 实际占比 实占多少格
      let WWidth = parseInt(w / PerWidth);
      let cm = WWidth + 120;
      this.$emit("cm", cm);
      // console.log("cm",cm);
      //判断区间
      if (w <= PerWidth * 10) {
        this.title = "小于130";
      } else if (w >= PerWidth * 90) {
        this.title = "大于210";
        return;
      } else if (WWidth >= WWidth + PerWidth / 2) {
        WWidth = WWidth + 1;
        this.title = WWidth + 120 + "cm";
        return;
      } else if (WWidth <= WWidth + PerWidth / 2) {
        WWidth = WWidth + 0;
        this.title = WWidth + 120 + "cm";
        return;
      } else if (WWidth == WWidth) {
        this.title = WWidth + 120 + "cm";
        return;
      }
    },
    move(event) {
      let odiv = event.target; //获取目标元素
      let fdiv = odiv.parentNode;
      let xdiv = fdiv.firstChild;
      let tdiv = this.$refs.tu;
      let disX = event.clientX - odiv.offsetLeft;
      // console.log("----", disX);

      document.onmousemove = (e) => {
        //鼠标按下并移动的事件
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = e.clientX - disX;
        this.CalculatedWidth(left);
        //获取实际宽度
        let OriginalWidth = this.$refs.name.clientWidth;
        if (left <= OriginalWidth && left >= 0) {
          this.CalculatedWidth(left);
          //   let bfb = left / OriginalWidth + "%";
          //   console.log(bfb);
          let a = this.mousemove(event);
          let z = a + "%";
          this.x = z;
          //移动当前元素
          xdiv.style.width = left + "px";
          tdiv.style.left = left + "px";
        }
      };

      document.onmouseup = () => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    a(event) {
      // 获得event对象兼容性写法
      // event || (event = window.event);
      // //获得target兼容性写法
      // event.target || event, srcElement;
      // //阻止浏览器默认行为兼容性写法
      event.preventDefault
        ? event.preventDefault()
        : (event.returnValue = false);
      // //阻止冒泡写法
      // event.stopPropagation
      //   ? event.stopPropagation()
      //   : (event.cancelBubble = true);
      //获取
      let a = this.mousemove(event);
      if (event.offsetX == a) {
        event.offsetX == event.offsetX;
        console.log("00", event.offsetX);
      }
      this.CalculatedWidth(event.offsetX);
      let z = a + "%";
      this.x = z;
    },
    mousemove(event) {
      event.preventDefault();
      //获取实际宽度
      let OriginalWidth = this.$refs.name.clientWidth;
      let a = event.offsetX;
      let c = a / OriginalWidth;
      let d = (c * 100).toFixed(4);
      let realVal = parseFloat(d).toFixed(4);
      if (realVal >= 100) return realVal == 100;
      return realVal;
    },
  },
  beforeDestroy() {
    /*页面销毁的时候要保存一些数据，就可以监听这个销毁的生命周期函数*/
    // console.log('实例销毁之前');
    clearInterval(this.intervalID);
  }
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.f-inline-block {
  display: inline-block;
}
.a {
  height: 16px;
  padding: 10px;
  background-color: #4d4d4d;
}

.f-cl:after,
.f-cl:before {
  display: table;
  content: "";
}
.f-cl:after {
  clear: both;
}
.f-cl:before {
  clear: both;
}
.f-fl {
  float: left;
}
.rule-bg {
  background: url("../../assets/images/rule.079996f.png") no-repeat;
}
.c-slide {
  user-select: none;
  background-color: #f4f4f4;
  border-radius: 4px;
  width: 510px;
  height: 40px;
  cursor: pointer;
  padding: 14px 14px 0;
}
.c-slide .slide-runway {
  width: 100%;
  height: 6px;
  position: relative;
  background-color: #d8d8d8;
}
.c-slide .slide-bar {
  height: 6px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fd686eff;
  z-index: 1;
}
.c-slide .slide-btn {
  position: absolute;
  top: -4px;
  transform: translateX(-50%);
  z-index: 2;
}
.c-slide .rule {
  display: block;
  float: left;
  color: #333333;
  font-size: 11px;
  padding: 2px 0 0 3px;
  line-height: 14px;
}
.c-slide .rule:last-child {
  position: relative;
}
.c-slide .rule:last-child::before {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 10px;
  border-left: 1px solid #4d4d4d;
  top: 0;
  left: 0;
}
.c-slide-button {
  border: 3px solid #fd686eff;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #fff;
  position: relative;
}
.c-slide-button .tooltip {
  transform: translate(-46%, -150%);
}
.c-slide-tooltip {
  padding: 5px;
  box-shadow: 0px 0px 4px 0px rgba(163, 163, 163, 0.36);
  border-radius: 4px;
  background-color: #fff;
  display: inline-block;
  color: #333333;
  white-space: nowrap;
  font-size: 12px;
  position: absolute;
}
.c-slide-tooltip:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 5px;
  border-style: solid;
  border-color: transparent;
  border-left-color: #fff;
  border-bottom-color: #fff;
  transform: rotate(-45deg) translateX(-50%);
  bottom: 0px;
  box-shadow: -1px 1px 4px 0px rgba(163, 163, 163, 0.36);
  left: 50%;
}
</style>