<template>
  <div class="registerA">
    <!-- 顶部 -->
    <!-- <div class="page">
      <div class="header">
        <div class="header-logo">
          <img src="../../assets/images/tubiao/yezai_logo.png" alt="" />
        </div>
        <router-link class="header-btn" to="/login"> 登录 </router-link>
      </div>
    </div> -->
    <Nlh />
    <!-- 主体 -->
    <div class="main" v-loading="loading">
      <div class="main-page">
        <!-- 左边 -->
        <div class="main-left">
          <img src="../../assets/images/login/rel.png" alt="" />
        </div>
        <!-- 右边 -->
        <div class="main-register" v-show="zsz">
          <div class="register-data">
            <div class="register">
              <div>编辑征婚资料</div>
              <div>（完整填写将为你推荐更合适的异性）</div>
            </div>
            <div class="common-data b-btn-sex" v-show="abcd">
              <p>我是一位</p>
              <div>
                <el-radio v-model="sex" label="0">男</el-radio>
                <el-radio v-model="sex" label="1">女</el-radio>
              </div>
            </div>
            <div class="common-data" v-show="abcd">
              <p>我的生日</p>
              <Ymdata @Ymdata="hui" />

              <p v-show="sr" style="color: red; font-size: 12px; margin-left: 10px">
                生日不能为空
              </p>
            </div>
            <div class="common-data" v-show="abcd">
              <p>婚姻状态</p>
              <div>
                <el-radio v-model="Marital" label="0">未婚</el-radio>
                <el-radio v-model="Marital" label="1">离异</el-radio>
                <el-radio v-model="Marital" label="2">丧偶</el-radio>
              </div>
            </div>
            <div class="common-data">
              <p>我的身高</p>
              <Scale @cm="ji" />
            </div>
            <div class="common-data">
              <p>我的工作地</p>
              <Workdata @workData="work" />
            </div>
            <div class="common-data">
              <p>我的学历</p>
              <div>
                <el-select v-model="education" placeholder="请选择">
                  <el-option v-for="item in educations" :key="item.value" :value="item.label" :label="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="common-data">
              <p>我的月收入</p>
              <div>
                <!--  -->
                <el-select v-model="income" placeholder="请选择">
                  <el-option v-for="item in incomes" :key="item.value" :value="item.label" :label="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <div class="register-account">
            <div class="register">
              <div>注册账户</div>
            </div>
            <div class="registerYz" style="display: flex; margin-top: 30px">
              <p>我的手机</p>
              <div>
                <el-input style="width: 310px" v-model="phone" maxlength="11" placeholder="请输入你的手机号"
                  @change="fn(1)"></el-input>
                <p class="title-phone-cuowu" v-show="a">手机号格式错误</p>
              </div>
            </div>
            <div class="registerYz" style="display: flex; margin-top: 15px">
              <p>创建密码</p>
              <div>
                <el-input style="width: 310px" placeholder="设置密码（8-20位，包含字母和数字）" maxlength="20" @keyup.native="Fn($event)"
                  @keydown.native="Fn($event)" v-model="password" @change="fn(2)" show-password></el-input>
                <p class="title-pw" style="width: 180px" v-show="b">
                  密码至少8位,包含字母和数字
                </p>
                <!-- 密码强度判断 -->
                <div class="Risk-statement" ref="rick" v-show="Risk"></div>
              </div>
            </div>
            <div class="account-btn">
              <el-checkbox v-model="checked"></el-checkbox>
              <p class="account-btn-title">
                已阅读和同意 <span @click="Sj(0)">也在网的服务协议</span> 和
                <span @click="Sj(1)">个人信息保护政策</span>
                并同意将本人提供之信息由也在网提供线上/线下服务使用
              </p>
              <p v-show="v" class="account-btn-iSv">请阅读和同意左侧的条款</p>
            </div>
          </div>
          <div class="registerBtn">
            <div class="register-btn" @click="register">同意协议并注册</div>
          </div>
        </div>
        <!-- 右边跳转01 -->
        <div class="main-register main-right" v-show="nan">
          <div class="info">
            <p>确认你的联系方式</p>
          </div>
          <!-- <div> -->
          <p class="info-yzm">验证码已通过短信发送至你的手机</p>
          <!-- </div> -->
          <div class="right-phone">
            <p style="font-size: 16px">手机号</p>
            <span style="margin-left: 16px; color: #fd686e">{{ phone }}</span>
            <p class="ghphone" @click="gh">更换手机号</p>
          </div>
          <div class="right-yzm">
            <p>验证码</p>
            <el-input v-model="yzm" maxlength="4" placeholder="请输入内容"></el-input>
            <el-button ref="btn" :disabled="disabled" :plain="true" @click="sendcode">
              {{ btntxt }}
            </el-button>
          </div>
          <div class="Btn-box" @click="registerSuccess">注册完成</div>
        </div>
        <!-- 修改手机号 -->
        <div class="main-register main-right mainRight" v-show="n">
          <div class="info">
            <p>确认你的联系方式</p>
          </div>
          <div class="right-yzm">
            <p>手机号</p>
            <el-input maxlength="11" v-model="newPhone" placeholder="请输入手机号"></el-input>
            <div class="Btn-quxiao" @click="it(1)">取消修改</div>
            <p v-show="newP" style="color: red; font-size: 12px; margin-left: 10px">
              手机格式错误
            </p>
          </div>
          <div class="Btn-xiugai" @click="it(2)">确认修改</div>
        </div>
      </div>
    </div>

    <!-- 手机号被注册弹框 -->
    <div class="Phone-mongolia" v-if="iSphoneMongolia">
      <!-- 内容 -->
      <div class="xg-phone">
        <img class="icon" :src="phoneMongoliacuowu" alt="" @click="phoneMongolia" />
        <div class="top" :style="{ background: 'url(' + phoneMongoliaBgc + ') no-repeat' }"></div>
        <p class="title">该号码已经注册也在网</p>
        <div class="Btn">
          <div class="Btn-box" @click="continueL">继续</div>
          <span class="Btn-box-ti">(原有账号的用户资料将被覆盖)</span>
          <div class="Btn-box">
            <router-link to="/login">登录原有账号</router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- <Footer /> -->
    <div class="Phone-mongolia" v-if="iSvipMongolia">
      <!-- 内容 -->
      <div class="xg-phone">
        <img class="icon" :src="phoneMongoliacuowu" alt="" @click="vipMongolia" />
        <div class="top" :style="{ background: 'url(' + phoneMongoliaBgc + ') no-repeat' }"></div>
        <p class="title ftRed">
          原有账号已开通会员，覆盖用户资料后<br />
          将无法继续享有会员权益
        </p>
        <div class="Btn">
          <div class="Btn-box" @click="coverBtn">覆盖</div>
          <!-- <span class="Btn-box-ti">(原有账号的用户资料将被覆盖)</span> -->
          <div class="Btn-box">
            <router-link to="/login">登录原有账号</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Ymdata from "@/components/Ymdata";
import Workdata from "@/components/Workdata";
import Scale from "@/components/Scale";
// import Footer from "@/components/layout/footer";
import local from "@/api/common/local.js"; //存储
import {
  Mregister,
  infocur,
  loginAuth,
  updateTerritory,
} from "@/api/login/login.js"; //sendSms
import { sendSms, validPhone, validSms } from "@/api/member/member.js"; //
import Nlh from "@/components/layout/nlh";
export default {
  metaInfo: {
    title: "也在网注册_免费注册也在网",
    meta: [
      { charset: "utf-8" },
      {
        name: "viewport",
        content:
          "width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no",
      },
      {
        name: "keywords",
        content: "交友,交友网,征婚交友,网上交友,征婚,征婚网,征婚交友网,交友中心,婚恋交友,同城交友,离异征婚,同城约会吧,约会吧征婚,约会吧交友,北京交友,佛山交友,找对象,婚介,二婚,再婚,也在网,也在"
      }, {
        name: "description",
        content: "也在网创始于2016年，也在网红娘均经过专业的婚恋学，心理学培训，结合多年的征婚服务经验，给您提供用心细致，专业个性化的红娘服务。也在网：成就天下姻缘！"
      }
    ],
  },

  data() {
    return {
      sex: "0",
      loading: false,
      abcd: true,
      Marital: "0",
      phone: "",
      password: "",
      checked: false,
      education: "",
      income: "",
      csrq: "",
      a: false,
      b: false,
      disabled: true,
      btntxt: "",
      num: 60,
      sr: false,
      p: false,
      v: false,
      zsz: true,
      n: false,
      nan: false,
      yzm: "",
      newP: false,
      stature: "",
      newPhone: "",
      workregion: "",
      educations: [
        {
          value: "高中及以下",
          label: "0",
        },
        {
          value: "中专",
          label: "1",
        },
        {
          value: "大专",
          label: "2",
        },
        {
          value: "大学本科",
          label: "3",
        },
        {
          value: "硕士",
          label: "4",
        },
        {
          value: "博士",
          label: "5",
        },
      ],
      incomes: [
        {
          value: "3000元以下",
          label: "0",
        },
        {
          value: "3001-5000元",
          label: "1",
        },
        {
          value: "5001-8000元",
          label: "2",
        },
        {
          value: "8001-12000元",
          label: "3",
        },
        {
          value: "12001-20000元",
          label: "4",
        },
        {
          value: "20001-50000元",
          label: "5",
        },
        {
          value: "50000元以上",
          label: "6",
        },
      ],
      iSphoneMongolia: false,
      iSvipMongolia: false,
      phoneMongoliaBgc: require("@/assets/images/Mantle/phoneWei.png"),
      phoneMongoliacuowu: require("@/assets/images/Mantle/cuowu.png"),
      Risk: false,
      intervalID: null,
      UserIsVip: false,
    };
  },
  components: {
    Ymdata,
    Scale,
    Workdata,
    Nlh, //顶部栏
  },
  created() {
    this.yuan();
    this.tz();
  },
  methods: {
    Fn() {
      let reg_pw =
        /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![_]+$)[0-9_A-Za-z]{8,20}$/;

      if (!reg_pw.test(this.password)) {
        this.b = true;
        this.Risk = false;
      } else {
        this.b = false;
      }
      // console.log(this.ruleForm.pass);
      if (this.password.length >= 8 && this.password.length < 10) {
        // console.log("风险");
        this.Risk = true;
        this.b = false;
        this.$refs.rick.style.background =
          "url(" + require("../../assets/images/forgot/Rick1.png") + ")";
      } else if (this.password.length >= 10) {
        // console.log("安全");
        this.Risk = true;
        this.b = false;
        this.$refs.rick.style.background =
          "url(" + require("../../assets/images/forgot/Rick2.png") + ")";
      } else {
        this.Risk = false;
      }
    },
    //判断密码
    checkStrong(sValue) {
      var modes = 0;
      //正则表达式验证符合要求的
      if (sValue.length < 8) return modes;
      if (/\d/.test(sValue)) modes++; //数字
      if (/[a-z]/.test(sValue)) modes++; //小写
      if (/[A-Z]/.test(sValue)) modes++; //大写
      if (/\W/.test(sValue)) modes++; //特殊字符
      //逻辑处理
      switch (modes) {
        case 1:
          return 1;
        // break;
        case 2:
          return 2;
        // break;
        case 3:
        case 4:
          return sValue.length < 4 ? 3 : 4;
        // break;
      }
      return modes;
    },
    async tz() {
      let Data = local.get("access_token");
      if (Data) {
        const { code } = await infocur(Data);
        if (code == 0) {
          this.$router.push({
            path: "/n/myyezai",
          });
        }
      }
    },
    work(data) {
      this.workregion = data;
    },
    //更改手机号
    it(it) {
      if (it == 1) {
        this.n = false;
        this.nan = true;
      } else if (it == 2) {
        let reg_tel =
          /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
        if (!reg_tel.test(this.newPhone)) {
          this.newP = true;
        } else {
          this.newP = false;
          this.phone = this.newPhone;
          this.nan = true;
          this.n = false;
          //重新发送短信
          this.num = 60;
          this.Btn();
          this.SMS();
        }
      }
    },
    gh() {
      this.nan = false;
      this.n = true;
    },
    ji(data) {
      this.stature = data;
    },
    hui(data) {
      this.csrq = data;
    },
    async sendSms() {
      const { code, data } = await sendSms();
      if (code == 0) {
        console.log("数据", data);
      }
    },
    //注册成功跳转
    async registerSuccess() {
      try {
        this.loading = true;
        let a = {
          code: this.yzm,
        };
        const D = await validSms(a); //校验验证码
        if (D.code == 0) {
          // 发请求
          let Data = {
            loginSource: 0,
            birthday: this.csrq,
            education: this.education,
            height: this.stature,
            maritalStatus: this.Marital,
            monthIncome: this.income,
            password: this.password,
            phonenumber: this.phone,
            registerStep: "",
            sex: this.sex,
            workAddrCityId: this.workregion.cityId,
            workAddrCityName: this.workregion.city,
            workAddrDistrictId: this.workregion.countyId,
            workAddrDistrictName: this.workregion.county,
            workAddrProvinceId: this.workregion.provinceId,
            workAddrProvinceName: this.workregion.province,
            remark: "pc",
          };
          let { code } = await Mregister(Data);
          if (code == 0) {
            this.loading = false;
            //注册成功,直接登录
            this.intervalID = setTimeout(() => {
              this.aunthLogin(this.phone, this.password);
            }, 1000);

            this.$message({
              offset: 100,
              message: "恭喜你，注册成功",
              type: "success",
            });
          } else if (code == 1) {
            this.$message({
              offset: 100,
              message: "注册失败",
              type: "error",
            });
          }
        } else if (D.code == 1) {
          this.$message.error({
            offset: 100,
            message: "短信验证码校验失败",
          });
        }
      } finally {
        this.loading = false;
      }
    },
    async aunthLogin(phone, pw) {
      let Data = {
        loginMethod: "P",
        loginSource: 0,
        loginType: "M",
        account: phone,
        // password: this.$md5(this.pw),
        password: pw,
      };
      const { code, data } = await loginAuth(Data);
      if (code == 0) {
        local.set("access_token", data.access_token); //存储令牌
        /**
         * 上报IP属地
         */
        updateTerritory();
        // 登陆成功
        this.$router.push({
          path: "/n/myyezai",
        });
      }
    },
    // 初始化获取路由信息
    yuan() {
      if (this.$route.params.id == 1) {
        this.abcd = false;
        this.csrq = this.$route.params.csrq;
        this.Marital = this.$route.params.Marital;
      } else {
        this.abcd = true;
      }
    },
    // 倒计时,发短信
    async Btn() {
      if (this.num > 0) {
        this.num--;
        this.btntxt = `已发送(${this.num}s)`;
        this.intervalID = setTimeout(this.Btn, 1000);
        this.$refs.btn.$el.style.backgroundColor = "#fafafaff";
        this.$refs.btn.$el.style.color = "#ADADADFF";
      } else {
        this.btntxt = "重新发送";
        this.$refs.btn.$el.style.backgroundColor = "#FD686EFF";
        this.$refs.btn.$el.style.color = "#ffffff";
        this.disabled = false;
      }
    },
    async SMS() {
      let a = {
        phonenumber: this.phone,
        busType: 0,
      };
      await sendSms(a);
    },
    //按钮
    sendcode() {
      this.num = 60;
      this.disabled = true;
      this.Btn();
      this.SMS();
    },
    zcPhone() {
      //手机号验证最新正则表达式
      let reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!reg_tel.test(this.phone)) {
        this.a = true;
      } else {
        this.a = false;
      }
    },
    zcYzm() {
      // console.log('');
      // 密码验证
      // let reg_pw =
      //   /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![_]+$)[0-9_A-Za-z]{8,20}$/;
      // if (!reg_pw.test(this.password)) {
      //   this.b = true;
      // } else {
      //   this.b = false;
      // }
      // console.log("数据123456", this.checkStrong(this.password));
    },
    fn(num) {
      if (num == 1) {
        this.zcPhone();
      } else if (num == 2) {
        this.zcYzm();
      }
    },
    async register() {
      //出生日期
      if (this.csrq == "") {
        this.sr = true;
      } else {
        this.sr = false;
      }
      //
      if (this.checked) {
        this.v = false;
      } else {
        this.v = true;
      }
      let reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!reg_tel.test(this.phone)) {
        this.a = true;
      } else {
        this.a = false;
      }
      let reg_pw =
        // ([a-zA-Z0-9]|[._])
        /^(?![0-9\W_!@#$%^&*`~()-+=]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)[0-9_A-Za-z\W_!@#$%^&*`~()-+=]{8,20}$/;
      // /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)[0-9_A-Za-z\W_!@#$%^&*`~()-+=]{8,20}$/;
      // /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![_]+$)[0-9_A-Za-z]{8,20}$/;
      console.log("密码", this.password);
      if (!reg_pw.test(this.password)) {
        this.b = true;
        this.Risk = false;
      } else {
        this.b = false;
      }
      if (this.sr || this.v || this.a || this.b) {
        console.log("");
      } else {
        let aa = {
          phonenumber: this.phone,
        };
        this.UserIsVip = false;
        const { code, data } = await validPhone(aa);
        if (code == 0) {
          if (data.count == 0) {
            console.log("shuju1");
            // if (data.isVipMember == 0) {
            this.zsz = false;
            this.nan = true;
            this.Btn();
            this.SMS();
            // }
            this.UserIsVip = false;
            //  let a = {
            //    phonenumber: this.phone,
            //    busType: 0,
            //  };
            //   const { code, data } = await sendSms(a);
            //  if (code == 0) {
            //    console.log("数据", data);
            //  }
          } else {
            // this.iSphoneMongolia = !this.iSphoneMongolia;
            this.iSphoneMongolia = true;
            if (data.isVipMember == 1) {
              this.UserIsVip = true;
            }
          }
        }
      }
    },
    // 弹框关闭
    phoneMongolia() {
      this.iSphoneMongolia = !this.iSphoneMongolia;
    },
    vipMongolia() {
      this.iSvipMongolia = !this.iSvipMongolia;
      this.UserIsVip = false;
    },
    async continueL() {
      if (this.UserIsVip) {
        // this.iSphoneMongolia = !this.iSphoneMongolia;
        // this.zsz = false;
        // this.nan = true;
        // this.Btn();
        // this.SMS();
        console.log("会员");
        this.iSvipMongolia = true;
        this.iSphoneMongolia = false;
      } else {
        console.log("账号注销");
        // this.iSphoneMongolia = !this.iSphoneMongolia;
        this.iSphoneMongolia = false;
        this.zsz = false;
        this.nan = true;
        this.Btn();
        this.SMS();
      }
    },
    coverBtn() {
      this.iSvipMongolia = !this.iSvipMongolia;
      this.zsz = false;
      this.nan = true;
      this.Btn();
      this.SMS();
    },
    jump() {
      // this.$router.push('/login')
      let rel = this.$router.resolve({
        path: "/login",
      });
      window.open(rel.href, "_blank");
    },
    //协议跳转
    Sj(index) {
      if (index == 0) {
        // console.log("000");

        let rel = this.$router.resolve({
          path: "/m/portal/register/prDeal",
        });
        window.open(rel.href, "_blank");
      } else if (index == 1) {
        // console.log("111");
        let rel = this.$router.resolve({
          path: "/m/portal/register/serverDeal",
        });
        window.open(rel.href, "_blank");
      }
    },
  },
  // destroyed() {
  //   clearInterval(this.Btn);
  // },
  beforeDestroy() {
    /*页面销毁的时候要保存一些数据，就可以监听这个销毁的生命周期函数*/
    // console.log('实例销毁之前');
    clearInterval(this.intervalID);
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: #fa5858;
  background: #fa5858;
}

/deep/ .el-radio__input.is-checked+.el-radio__label {
  color: #000;
}

/deep/ .el-radio {
  color: #000;
  cursor: pointer;
  margin-right: 30px;
}

/deep/ .el-select .el-input__inner:focus {
  background: rgba(255, 255, 255, 0);
}

/deep/ .el-button {
  background-color: #fa5858;
}

/deep/ .value {
  color: #adadad !important;
  text-align: center !important;
}

.registerA {
  height: 100%;
  background-color: #fafafa;
}

.main {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  box-sizing: border-box;
  // background-color: #fafafaff;
  background-color: #fafafa;

  // padding-top: 20px;
  .main-page {
    min-width: 1200px;
    display: flex;
    justify-content: space-between;

    .main-left {
      width: 300px;
      height: 866px;
    }

    .main-register {
      width: 880px;
      background-color: #ffffff;
      // height: 900px;
      height: 866px;
      padding-left: 30px;
      padding-top: 33px;
      box-sizing: border-box;
      margin-bottom: 32px;

      .register-data {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        div {
          display: flex;
          align-items: center;
        }

        p {
          font-size: 16px;
          width: 134px;
        }

        .b-btn-sex {
          font-size: 18px;

          p {
            width: 142px;
          }

          /deep/ .el-radio__input.is-checked .el-radio__inner {
            border-color: #fa5858;
            background: #fa5858;
          }

          /deep/ .el-radio__input.is-checked+.el-radio__label {
            color: #000;
          }

          /deep/ .el-radio {
            color: #000;
            cursor: pointer;
            margin-right: 30px;
          }
        }

        .common-data {
          box-sizing: border-box;
          padding-left: 30px;
          // margin-top: 16px;
          padding-top: 16px;
          box-sizing: border-box;
          height: 56px;
          display: flex;
          align-items: center;

          /deep/ .year .name {
            color: #000;
          }

          /deep/ .month-tips,
          /deep/ .day-tips {
            color: #000;
            text-align: center;
          }

          //修改
          /deep/ .el-input__inner {
            background-color: #f5f5f5ff;
            border: none;
            outline: none;
          }
        }

        /deep/ .register {
          display: flex;
          height: 30px;
          align-items: center;

          div:nth-child(1) {
            margin-left: 20px;
            font-size: 16px;

            font-weight: bold;
            color: #333333;
            line-height: 25px;
          }

          div:nth-child(2) {
            font-size: 14px;
            color: #333333;
          }
        }

        .register::before {
          content: "";
          display: inline-block;
          width: 4px;
          margin-right: 4px;
          height: 18px;
          background: #fd686eff;
        }
      }

      .register-account {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        justify-content: space-between;
        font-size: 16px;

        .register {
          display: flex;

          div:nth-child(1) {
            margin-left: 20px;
          }

          div:nth-child(2) {
            margin-left: 69px;
          }
        }

        p {
          font-size: 16px;
        }

        .register::before {
          content: "";
          display: inline-block;
          width: 4px;
          margin-right: 4px;
          height: 18px;
          background: #fd686eff;
        }

        .account-btn {
          display: flex;
          margin-top: 20px;
          margin-left: 164px;

          /deep/ .el-checkbox {
            box-sizing: border-box;
            padding-top: 5px;
          }

          .account-btn-title {
            font-size: 14px;
            line-height: 24px;
            width: 476px;
            font-size: 14px;
            margin-left: 5px;

            span {
              text-decoration: underline;
              color: red;
              cursor: pointer;
            }
          }

          .account-btn-iSv {
            color: red;
            font-size: 12px;
            margin-left: 10px;
            display: flex;
            align-items: center;
          }
        }

        .registerYz {
          margin-left: 30px;
          display: flex;
          box-sizing: border-box;
          align-items: center;
          position: relative;

          p {
            width: 134px;
          }

          // .title-phone,
          .title-pw {
            position: absolute;
            // top: 48px;
            // left: 152px;
            top: 14px;
            right: 187px;
            color: red;
            font-size: 12px;
          }

          .title-phone-cuowu {
            position: absolute;
            // top: 48px;
            // left: 152px;
            top: 14px;
            right: 232px;
            color: red;
            font-size: 12px;
          }

          .Risk-statement {
            position: absolute;
            // top: 80px;
            // left: 354px;
            top: 10px;
            right: 240px;
            width: 90px;
            height: 20px;
            border-radius: 11px;
            border: 1px solid #ececec;
          }
        }
      }

      .registerBtn {
        display: flex;
        justify-content: center;
        margin-top: 30px;

        .register-btn {
          width: 224px;
          height: 44px;
          background: #fd686e;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          cursor: pointer;
        }
      }

      // .registerYz {
      //   position: relative;
      //   .title-phone,
      //   .title-pw {
      //     position: absolute;
      //     top: 48px;
      //     left: 152px;
      //     color: red;
      //     font-size: 12px;
      //   }
      // }
    }

    .main-right {
      padding-left: 30px;
      padding-right: 30px;

      // padding-top: 20px;
      .info {
        display: flex;
        justify-content: space-between;
        position: relative;
        height: 32px;
        border-bottom: 1px solid #f4f4f4ff;

        p {
          font-size: 16px;
          font-weight: bold;
          color: #000;
          line-height: 22px;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
        }

        p::before {
          content: "";
          width: 4px;
          height: 16px;
          background: #fd686e;
          margin-right: 4px;
          // position: absolute;
          // top: 3px;
          // left: -8px;
        }
      }

      .info-yzm {
        margin-left: 100px;
        margin-top: 28px;
        margin-bottom: 40px;
        font-size: 16px;
        color: #666;
      }

      .right-phone {
        display: flex;
        // margin-top: 34px;
        margin-bottom: 30px;
        font-size: 16px;

        .ghphone {
          margin-left: 16px;
          color: #5797ffff;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .right-yzm {
        margin-top: 24px;
        display: flex;
        align-items: center;

        p {
          font-size: 14px;
          margin-right: 16px;
          color: #333333;
        }

        /deep/ .el-input {
          width: 200px;
          height: 42px;
          margin-right: 16px;
          background: #f5f5f5ff;
        }

        .Btn-quxiao {
          width: 120px;
          height: 42px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          color: #ffffff;
          border-radius: 4px;
          background-color: #fd686e;
        }
      }

      .Btn-box {
        width: 200px;
        height: 42px;
        background: #fd686e;
        border-radius: 6px;
        border: 1px solid #fd686e;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        margin-left: 100px;
        margin-top: 30px;
        cursor: pointer;
      }
    }

    .mainRight {
      .Btn-xiugai {
        width: 200px;
        height: 42px;
        background-color: #fd686e;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 58px;
        border-radius: 6px;
        margin-top: 16px;
      }
    }
  }
}

.Phone-mongolia {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;

  .xg-phone {
    z-index: 889;
    box-sizing: border-box;
    background-color: #ffffffff;
    position: fixed;
    top: 256px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 8px;
    width: 380px;
    // height: 314px;
    height: 328px;
    background: #ffffff;

    .top {
      // background: url("../../assets/images/Mantle/menc.png") no-repeat;
      color: #ffffff;
      font-size: 18px;
      height: 144px;
      padding-top: 62px;
      box-sizing: border-box;

      p {
        text-align: center;
      }
    }

    .title {
      text-align: center;
      padding-top: 36px;
      font-size: 16px;
      box-sizing: border-box;
      color: #333;
    }

    .Btn {
      display: flex;
      // justify-content: center;
      justify-content: space-around;
      margin-top: 36px;
      position: relative;

      .Btn-box-ti {
        position: absolute;
        color: #fd686e;
        font-size: 12px;
        bottom: -14px;
        left: 15px;
      }

      .Btn-box {
        width: 150px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fd686eff;
        color: #ffffff;
        font-size: 14px;
        border-radius: 4px;
        cursor: pointer;

        a {
          color: #ffffff;
          font-size: 14px;
        }
      }
    }

    .icon {
      cursor: pointer;
      position: absolute;
      top: -10px;
      right: -10px;
    }

    .ftRed {
      color: red !important;
    }
  }
}</style>
